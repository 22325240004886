import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AiFillCopy } from 'react-icons/ai';

import './MarkdownStyles.css';

interface IMarkdown {
  markdown: string;
}

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
      {props.children}
    </a>
  );
}

const Markdown = (props: IMarkdown) => {
  const { markdown } = props;

  return (
    <div style={{ position: "relative" }}>
      <ReactMarkdown
        children={markdown}
        remarkPlugins={[remarkGfm, remarkDirective]}
        className="markdown"
        components={{
          a: LinkRenderer,
          code({ node, className, children }) {
            const match = /language-(\w+)/.exec(className || '')
            return !match ? (
              <code className={className}>
                {children}
              </code>
            ) : (
              <div style={{ position: "relative" }}>
                <CopyToClipboard text={String(children).replace(/\n$/, '')}>
                  <button 
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer'
                    }}>
                    <AiFillCopy color='white' size={20}/>
                  </button>
                </CopyToClipboard>
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, '')}
                  style={atomDark}
                  language={match[1]}
                  PreTag="div"
                />
              </div>
            )
          }
        }}
      />
    </div>
  );
}

export default Markdown;
