import { useState, useRef, useEffect } from 'react';
import {
  FaRegPaperPlane,
  FaTimes,
  FaMicrophone,
  FaPaperclip,
  FaGlobe,
} from 'react-icons/fa';
import { speechToText } from '../../services/chat.service';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import { useApp, useAuth } from '../../hooks';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  CircularProgress,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Google as GoogleIcon } from '@mui/icons-material';
import {
  checkGoogleAuth,
  connectWithGoogle,
} from '../../services/google-tool.service';

import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ArticleIcon from '@mui/icons-material/Article';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ShareIcon from '@mui/icons-material/Share';

interface CustomMentionInputProps {
  zapierActions: any[];
  value: any;
  onChange: (newValue: any) => any;
  onMentionSelect: (mention: any) => any;
  placeholder: string;
  sendMessage: (
    input: string,
    selectedMention: any,
    audioBlob: any,
    files: File[],
    hasInternetAccess?: boolean
  ) => any;
  loading: boolean;
  isCollaborativeThread?: boolean;
  isThreadOwner?: boolean;
  organisation_id: string;
}

const GradientButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: 'auto',
  padding: '0.5rem',
  background: theme.palette.gradients.primary,
  color: '#FFFFFF',
  '&:hover': {
    background: theme.palette.gradients.primaryHover,
  },
  '&:active': {
    background: theme.palette.gradients.primaryActive,
  },
  transition: 'all 0.3s ease-in-out',
  boxShadow: `0 4px 14px 0 ${theme.palette.primary}`,
}));

const CustomMentionInput = ({
  zapierActions,
  value,
  onChange,
  onMentionSelect,
  placeholder,
  sendMessage,
  loading,
  isCollaborativeThread,
  isThreadOwner,
  organisation_id,
}: CustomMentionInputProps) => {
  const [input, setInput] = useState('');
  const [selectedMentions, setSelectedMentions] = useState<any[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [attachedFiles, setAttachedFiles]: any = useState<File[]>([]);
  const inputRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const recorderRef: any = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const { dispatch }: any = useApp();
  const { threadIdParam }: any = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isGoogleAuth, setIsGoogleAuth] = useState(false);
  const { auth }: any = useAuth();
  const [isInternetAccess, setIsInternetAccess] = useState(false);

  useEffect(() => {
    clearInput();
  }, [threadIdParam]);

  const handleInputChange = async (e: any) => {
    const newValue = e.target.value;
    setInput(newValue);
    onChange(newValue);

    // Uncomment to activate Tools

    if (newValue.includes('@')) {
      if (!isDropdownOpen) {
        await handleAtSymbol();
      }
    } else {
      setIsDropdownOpen(false);
    }
  };

  const handleAtSymbol = async () => {
    const isAuth = await checkGoogleAuth(organisation_id);
    setIsGoogleAuth(isAuth);

    if (isAuth) {
      const googleWorkspaceTools = [
        { id: 'send_email_using_google', display: 'Send email', icon: EmailIcon },
        { id: 'create_calendar_event_in_google_calendar', display: 'Create Calendar Event', icon: EventIcon },
        { id: 'list_calendar_events_from_google_calendar', display: 'List Calendar Events', icon: EventIcon },
        { id: 'update_calendar_event_in_google_calendar', display: 'Update Calendar Event', icon: EventIcon },
        { id: 'get_event_details_from_google_calendar', display: 'Get Event Details', icon: EventIcon },

        // Google Docs Tools
        { id: 'create_google_doc', display: 'Create Google Doc', icon: NoteAddIcon },
        { id: 'read_google_doc', display: 'Read Google Doc', icon: ArticleIcon },
        { id: 'append_text_to_google_doc', display: 'Append Text to Google Doc', icon: TextFieldsIcon },
        { id: 'replace_text_in_google_doc', display: 'Replace Text in Google Doc', icon: DriveFileRenameOutlineIcon },
        { id: 'list_google_docs', display: 'List Google Docs', icon: DescriptionIcon },

        // Google Sheets Tools
        { id: 'search_google_sheet_by_name', display: 'Search Google Sheet by Name', icon: DescriptionIcon },
        { id: 'read_google_sheet', display: 'Read Google Sheet', icon: DescriptionIcon },
        { id: 'create_google_sheet', display: 'Create Google Sheet', icon: DescriptionIcon },
        { id: 'manage_google_sheet_data', display: 'Manage Google Sheet Data', icon: DescriptionIcon },
        { id: 'create_graph_from_google_sheet', display: 'Create Graph from Google Sheet', icon: DescriptionIcon },

        // Google Drive Files
        { id: 'list_drive_files', display: 'List Drive Files', icon: FolderOpenIcon },
        { id: 'share_drive_file', display: 'Share Drive File', icon: ShareIcon },
      ];

      setSuggestions([
        {
          section: 'Google Workspace',
          tools: googleWorkspaceTools,
        },
      ]);
    } else {
      setSuggestions([
        {
          section: 'Google Workspace',
          tools: [],
          showConnect: true,
        },
      ]);
    }

    setIsDropdownOpen(true);
  };

  const handleKeyDown = (e: any) => {
    if (
      e.key === 'Enter' &&
      !isDropdownOpen &&
      !e.shiftKey &&
      input.trim() !== '' &&
      input.length > 0
    ) {
      sendMessage(input, selectedMentions[0] ?? {}, audioBlob, attachedFiles, isInternetAccess);
      setTimeout(() => {
        clearInput();
      }, 100);
    }
  };

  const selectMention = (suggestion: any) => {
    if (suggestion.id === 'authenticate') {
      connectGoogle();
      return;
    }

    const newMentions = [...selectedMentions, { ...suggestion }];
    setSelectedMentions(newMentions);
    onMentionSelect(newMentions);
    setIsDropdownOpen(false);

    const newInput = input.replace(/@\S*$/, '').trim();
    setInput(newInput);
    onChange(newInput);

    if (inputRef.current) {
      (inputRef.current as any).focus();
    }
  };

  const connectGoogle = async () => {
    if (auth.user.uid) {
      await connectWithGoogle(auth.user.uid, organisation_id);
    } else {
      console.error('User ID is not available');
    }
    setIsDropdownOpen(false);
  };

  const clearInput = () => {
    setInput('');
    setSelectedMentions([]);
    onMentionSelect([]);
    setAudioBlob(null);
    setAttachedFiles([]);
    if (inputRef.current) {
      (inputRef.current as any).style.height = 'auto';
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeMention = (mentionId: any) => {
    const newMentions = selectedMentions.filter(
      (m: any) => m.id !== mentionId
    );
    setSelectedMentions(newMentions);
    onMentionSelect(newMentions);
  };

  const handleFileChange = (e: any) => {
    const newFiles = Array.from(e.target.files);
    const totalFiles = attachedFiles.length + newFiles.length;
    if (totalFiles > 5) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          show: true,
          title: 'File Attachment',
          message: 'You can attach a maximum of 5 files at a time.',
          type: 'error',
        },
      });
      return;
    }

    let hasInvalidFile = false;
    const validFileTypes = ['pdf', 'docx'];
    const newFilesData: File[] = [];

    newFiles.forEach((file: any) => {
      const fileType = file.name.split('.').slice(-1)[0];
      if (validFileTypes.includes(fileType)) {
        newFilesData.push(file);
      } else {
        hasInvalidFile = true;
      }
    });

    if (hasInvalidFile) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          show: true,
          title: 'Invalid File Type',
          message: 'Only PDF and DOCX files are allowed.',
          type: 'error',
        },
      });
      return;
    }

    setAttachedFiles([...attachedFiles, ...newFilesData]);
  };

  const removeFile = (fileIndex: number) => {
    setAttachedFiles(
      attachedFiles.filter((_: any, index: any) => index !== fileIndex)
    );
  };

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia not supported');
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioContext = new AudioContext();
    const mediaStreamSource = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    mediaStreamSource.connect(analyser);

    const dataArray: any = new Uint8Array(analyser.frequencyBinCount);

    let silenceDuration = 0;

    const checkSilence = () => {
      analyser.getByteTimeDomainData(dataArray);
      const maxAmplitude = Math.max(...dataArray);
      const silenceThreshold = 130;
      if (maxAmplitude < silenceThreshold) {
        silenceDuration += 500;
        if (silenceDuration >= 5000) {
          stopRecording();
        } else {
          setTimeout(checkSilence, 500);
        }
      } else {
        silenceDuration = 0;
        setTimeout(checkSilence, 500);
      }
    };

    recorderRef.current = new MediaRecorder(stream);
    recorderRef.current.ondataavailable = (e: any) => {
      setAudioBlob(e.data);
      recorderRef.current.onstop = () => {
        setIsRecording(false);
        sendAudio(e.data);
      };
    };

    setIsRecording(true);
    recorderRef.current.start();
    checkSilence();
    setTimeout(() => {
      stopRecording();
    }, 60000);
  };

  const stopRecording = async () => {
    if (
      recorderRef.current &&
      recorderRef.current.state === 'recording'
    ) {
      try {
        recorderRef.current.stream
          .getTracks()
          .forEach((track: any) => track.stop());
        await recorderRef.current.stop();
      } catch (error) {
        console.error('Error stopping recording:', error);
      }
    } else {
      console.error('No recording to stop');
    }
  };

  const sendAudio = async (voiceData: any) => {
    if (!voiceData) {
      console.error('No audio recorded');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(voiceData);
    reader.onloadend = async () => {
      const base64String = reader.result;
      const response: any = await speechToText(
        base64String,
        organisation_id
      );
      setInput(input + ' ' + response.data.data);
    };
  };

  useEffect(() => {
    return () => {
      if (
        recorderRef.current &&
        recorderRef.current.state === 'recording'
      ) {
        recorderRef.current.stop();
      }
    };
  }, []);

  // Determine searchTerm from what the user typed after '@'
  let searchTerm = '';
  if (isDropdownOpen && input.includes('@')) {
    const lastAtIndex = input.lastIndexOf('@');
    searchTerm = input.slice(lastAtIndex + 1).trim().toLowerCase();
  }

  // Filter tools based on searchTerm
  const filteredSuggestions = suggestions.map((section: any) => {
    return {
      ...section,
      tools: section.tools
        ? section.tools.filter((tool: any) =>
            tool.display.toLowerCase().includes(searchTerm)
          )
        : [],
    };
  });

  // Check if we have any tools available after filtering
  const totalFilteredTools = filteredSuggestions.reduce(
    (acc: number, section: any) => acc + section.tools.length,
    0
  );

  // If no tools are found, close the dropdown
  useEffect(() => {
    if (isDropdownOpen && totalFilteredTools === 0 && !filteredSuggestions.some((sec: any) => sec.showConnect)) {
      setIsDropdownOpen(false);
    }
  }, [totalFilteredTools, filteredSuggestions, isDropdownOpen]);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        borderRadius: 2,
        position: 'relative',
        boxShadow: (theme) =>
          `0 4px 14px 0 ${theme.palette.gradients.primaryActive}`,
      }}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={1}
          alignItems="flex-start"
        >
          {!isMobile && (
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip title="Attach">
                <IconButton
                  onClick={() => fileInputRef.current?.click()}
                  disabled={loading}
                >
                  <GradientButton>
                    <FaPaperclip size={20} />
                  </GradientButton>
                </IconButton>
              </Tooltip>
              <Tooltip
                title={`Internet Access: ${
                  isInternetAccess ? 'On' : 'Off'
                }. Toggling this on will provide DataLinker AI access to the internet.`}
              >
                <IconButton
                  onClick={() => {
                    setIsInternetAccess(!isInternetAccess);
                  }}
                  disabled={loading}
                >
                  <GradientButton
                    style={{ color: isInternetAccess ? '#90EE90' : 'white' }}
                  >
                    <FaGlobe size={20} />
                  </GradientButton>
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <TextField
            label={placeholder}
            multiline
            maxRows={4}
            inputRef={inputRef}
            value={input}
            fullWidth
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            variant="outlined"
            InputProps={{
              style: { resize: 'none' },
            }}
            sx={{
              '& .MuiInputBase-root': {
                flexDirection: 'column-reverse',
              },
            }}
          />
          {!isMobile && (
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip title={isRecording ? 'Stop Recording' : 'Record'}>
                <IconButton
                  onClick={isRecording ? stopRecording : startRecording}
                  disabled={loading}
                >
                  <GradientButton>
                    {isRecording ? <FaTimes size={20} /> : <FaMicrophone size={20} />}
                  </GradientButton>
                </IconButton>
              </Tooltip>
              <Tooltip title="Send">
                <IconButton
                  onClick={() => {
                    if (input.trim() !== '' && input.length > 0) {
                      sendMessage(
                        input,
                        selectedMentions ?? {},
                        audioBlob,
                        attachedFiles,
                        isInternetAccess
                      );
                      clearInput();
                    }
                  }}
                  disabled={loading || (!isCollaborativeThread && !isThreadOwner)}
                >
                  <GradientButton>
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <FaRegPaperPlane size={20} />
                    )}
                  </GradientButton>
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        {isMobile && (
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={1}
            mt={1}
          >
            <Tooltip title="Attach">
              <IconButton
                onClick={() => fileInputRef.current?.click()}
                disabled={loading}
              >
                <GradientButton>
                  <FaPaperclip size={20} />
                </GradientButton>
              </IconButton>
            </Tooltip>
            <Tooltip title="Internet Access">
              <IconButton
                onClick={() => {
                  setIsInternetAccess(!isInternetAccess);
                }}
                disabled={loading}
              >
                <GradientButton>
                  <FaGlobe size={20} />
                </GradientButton>
              </IconButton>
            </Tooltip>
            <Tooltip title={isRecording ? 'Stop Recording' : 'Record'}>
              <IconButton
                onClick={isRecording ? stopRecording : startRecording}
                disabled={loading}
              >
                <GradientButton>
                  {isRecording ? <FaTimes size={20} /> : <FaMicrophone size={20} />}
                </GradientButton>
              </IconButton>
            </Tooltip>
            <Tooltip title="Send">
              <IconButton
                onClick={() => {
                  if (input.trim() !== '' && input.length > 0) {
                    sendMessage(
                      input,
                      selectedMentions[0] ?? {},
                      audioBlob,
                      attachedFiles,
                      isInternetAccess
                    );
                    clearInput();
                  }
                }}
                disabled={loading || (!isCollaborativeThread && !isThreadOwner)}
              >
                <GradientButton>
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <FaRegPaperPlane size={20} />
                  )}
                </GradientButton>
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {selectedMentions.length > 0 && (
          <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
            {selectedMentions.map((mention: any, index: any) => (
              <Chip
                key={index}
                label={mention.display}
                onDelete={() => removeMention(mention.id)}
                color="primary"
                icon={mention?.icon ? <mention.icon /> : undefined}
              />
            ))}
          </Box>
        )}
      </Box>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept=".pdf, .docx"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {attachedFiles.length > 0 && (
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          {attachedFiles.map((file: any, index: any) => (
            <Chip
              key={index}
              label={file.name}
              onDelete={() => removeFile(index)}
              color="secondary"
              sx={{ margin: '0.5rem' }}
            />
          ))}
        </Box>
      )}
      {isDropdownOpen && totalFilteredTools > 0 && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            left: 0,
            bottom: '100%',
            zIndex: 50,
            width: '100%',
            maxHeight: '300px',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box display="flex" justifyContent="flex-end" p={1}>
            <IconButton
              size="small"
              onClick={() => setIsDropdownOpen(false)}
            >
              <FaTimes />
            </IconButton>
          </Box>
          {filteredSuggestions.map((suggestion: any, index: number) => (
            <Box key={index}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  backgroundColor: 'background.default'
                }}
              >
                <Box sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                  {suggestion.section}
                </Box>
                {suggestion.showConnect && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={connectGoogle}
                    startIcon={<GoogleIcon />}
                  >
                    Connect
                  </Button>
                )}
              </Box>
              {suggestion.tools && suggestion.tools.length > 0 && (
                <List sx={{ py: 0 }}>
                  {suggestion.tools.map((tool: any) => (
                    <ListItem
                      button
                      key={tool.id}
                      onClick={() => selectMention(tool)}
                    >
                      <ListItemIcon>
                        {tool.icon && <tool.icon />}
                      </ListItemIcon>
                      <ListItemText primary={tool.display} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          ))}
        </Paper>
      )}
    </Paper>
  );
};

export default CustomMentionInput;
